<template>
  <div>
    <h1>Dashboard</h1>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: {},
      details: {},
      loading: false,
    }
  },
  computed: {
  },
  created() {
  },
  methods: {
  },
}
</script>

<style lang="scss">
</style>
